import { render, staticRenderFns } from "./ContactInfoRow.vue?vue&type=template&id=f9f4dc08&scoped=true&"
import script from "./ContactInfoRow.vue?vue&type=script&lang=js&"
export * from "./ContactInfoRow.vue?vue&type=script&lang=js&"
import style0 from "./ContactInfoRow.vue?vue&type=style&index=0&id=f9f4dc08&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9f4dc08",
  null
  
)

export default component.exports